<template>
  <div>
    <div class="row">
      <div
        class="col-sm-4"
        v-for="(course, index) in classes"
        :key="index"
        style="padding: 1rem;"
      >
        <router-link
          :to="{
            name: routerName,
            params: { id: course.id }
          }"
          style="text-decoration: none;"
        >
          <el-card
            class="course-card"
            style="max-width:350px;margin: 0 auto;"
            :body-style="{ padding: '0px' }"
            shadow="hover"
          >
            <div class="card-cover">
              {{ course.name.substr(0, 3) }}
            </div>
            <div style="width: 100%;padding: 14px;">
              <h5
                style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
              >
                {{ course.name }}
              </h5>
              <div
                style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
              >
                {{ course.description }}
              </div>
              <div class="bottom clearfix">
                <time class="time">
                  {{ $t("TeacherCourses.Number of students") }}: {{ course.student_users_count }}
                </time>
                <router-link
                  :to="{
                    name: routerName,
                    params: { id: course.id }
                  }"
                >
                  <el-button type="text" class="button">
                    {{ $t("TeacherCourses.View Class") }}
                  </el-button>
                </router-link>
              </div>
            </div>
          </el-card>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: ["classes", "routerName"],
  data() {
    return {};
  },
  computed: {
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.course-card {
  cursor: pointer;
}
.card-cover {
  background-color: var(--themeColor);
  width: 100%;
  height: 12rem;
  font-size: 4rem;
  line-height: 12rem;
  font-weight: 900;
  color: white;
  padding: 0 1rem;
  overflow: hidden;
  text-align: center;
}

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
</style>
